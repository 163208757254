<template>
  <div class="row m-0 justify-content-center">
    <div class="col-12 card_holder p-0 bg">
      <div class="row m-0 border-bottom">
        <div class="col-12 p-3 title text-center">
          Quick Donate
        </div>
      </div>
      <div class="row mx-0 my-3 justify-content-center">
        <div class="col-12 col-lg-10 p-0">
          <div class="row m-0">
            <div class="col-12 p-0">
              <CurrencyField placeholder="0.00" id="amount" size="big" v-model="donation.amount" :currencySymbol="defaultCurrency" />
            </div>
          </div>
        </div>
      </div>
      <div class="row m-0 justify-content-center">
        <div class="col-10 col-lg-8 p-0">
          <div class="row m-0">
            <div class="col-12 p-0 mb-3">
              <SelectAll
                v-model="selectedParentProject"
                id="project"
                :options="parentProjects"
                placeholder="Projects"
                displayValue="description"
                color="white"
                :isSearch="true"
                v-if="parentProjects && parentProjects.length > 0"
              />
            </div>
            <div class="col-12 p-0 mb-3" v-if="Object.keys(selectedParentProject).length !== 0 && childProjects.length > 0">
              <SelectAll
                v-model="selectedChildProject"
                id="project"
                :options="childProjects"
                placeholder="Children Projects"
                displayValue="description"
                color="white"
                :autoSelect="true"
                :isSearch="true"
              />
            </div>
            <div class="col-12 p-0 mb-3">
              <SelectAll
                v-if="Object.keys(selectedProject).length !== 0 && Object.keys(selectedProject.projectDonationTypeMaps).length > 0"
                v-model="donationType"
                id="project"
                :options="selectedProject.projectDonationTypeMaps"
                displayValue="donationType"
                subDisplayValue="description"
                color="white"
                placeholder="Donation Type"
                :autoSelect="true"
              />
              <Select
                v-else
                v-model="donationType"
                id="project"
                :options="selectedProject.projectDonationTypeMaps"
                placeholder="Donation Type"
                displayValue="donationType"
                subDisplayValue="description"
                color="white"
                disabled="disabled"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row m-0 bg_green">
        <div class="col-12 p-3 title text-center">
          <Button color="red" btnText="Donate Now" icon="arrow" @buttonClicked="goDonate" class="mb-1" :disabled="donation.amount < 0.01 || Object.keys(selectedProject).length === 0 || !donationType">
            <IconArrowForward color="white" size="size20" />
          </Button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    CurrencyField: defineAsyncComponent(() => import('@/components/CurrencyFieldCenter.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    Select: defineAsyncComponent(() => import('@/components/SelectProject.vue')),
    IconArrowForward: defineAsyncComponent(() => import('@/components/icons/IconArrowForward.vue'))
  },
  name: 'Quick Donate',
  data () {
    return {
      defaultCurrency: process.env.VUE_APP_CURRENCY_SYMBOL,
      donorType: '',
      selectedProject: {},
      selectedParentProject: {},
      selectedChildProject: {},
      donation: {
        amount: null,
        currency: this.defaultCurrency,
        projectId: '',
        donationTypes: []
      },
      donationType: ''
    }
  },
  beforeMount () {
    this.donation.currency = this.defaultCurrency
    this.setDefaults()
  },
  watch: {
    selectedProject () {
      if (this.selectedProject) {
        this.donation.projectId = this.selectedProject.projectID
      }
    },
    selectedParentProject () {
      if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
        this.selectedChildProject = {}
        this.selectedProject = this.selectedParentProject
      } else {
        this.selectedProject = this.selectedParentProject
      }
    },
    selectedChildProject () {
      if (this.selectedChildProject && Object.keys(this.selectedChildProject).length > 0) {
        this.selectedProject = this.selectedChildProject
      }
    }
  },
  computed: {
    ...mapGetters([
      'adminProjects',
      'user',
      'organisations'
    ]),
    isUK () {
      if (process.env.VUE_APP_COUNTRY === 'UK') {
        return true
      } else {
        return false
      }
    },
    parentProjects () {
      return this.adminProjects.filter(project => {
        if (project.parentProjectID !== 0) {
          return false
        }
        return true
      })
    },
    childProjects () {
      const arr = this.adminProjects.filter(project => {
        if (this.selectedParentProject && project.parentProjectID !== this.selectedParentProject.projectID) {
          return false
        }
        return true
      })
      arr.sort((a, b) => (a.description > b.description ? 1 : -1))
      return arr
    }
  },
  methods: {
    ...mapActions([
      'addToCart',
      'clearCart'
    ]),
    goDonate () {
      this.addDonationToCart()
      this.$router.push('/checkout/0')
    },
    addDonationToCart () {
      this.selectedProject.projectDonationTypeMaps.forEach((dType) => {
        const obj = { type: dType, amount: null }
        if (dType !== this.donationType) {
          this.donation.donationTypes.push(obj)
        } else {
          this.donation.donationTypes.push({ type: this.donationType, amount: this.donation.amount })
        }
      })
      this.addToCart(this.donation)
      this.isAdded = true
      this.donation = {
        amount: null,
        currency: this.defaultCurrency,
        projectId: '',
        donationTypes: []
      }
      this.selectedProject = {}
      this.selectedParentProject = {}
      this.selectedChildProject = {}
      setTimeout(() => {
        this.isAdded = false
      }, 2000)
    },
    setDefaults () {
      let defaultProject = {}
      if (this.isUK) {
        this.donation.amount = 5
        const mostInNeed = this.adminProjects.filter(project => {
          if (project.description.toLowerCase() !== 'most in need') {
            return false
          } else {
            return true
          }
        })
        defaultProject = mostInNeed[0]
      } else {
        this.donation.amount = 100
        const mostInNeed = this.adminProjects.filter(project => {
          if (project.projectID !== 28) {
            return false
          } else {
            return true
          }
        })
        defaultProject = mostInNeed[0]
      }
      this.defaultDonationType(defaultProject)
      this.selectedParentProject = { ...defaultProject }
    },
    defaultDonationType (val) {
      if (val && Object.keys(val).length > 0) {
        val.projectDonationTypeMaps.forEach((dType) => {
          if (dType.donationType.donationTypeID === 3) {
            this.donationType = dType
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.card_holder {
  border-radius: 15px;
}
.title {
  color: #3A3A3A;
  font-family: "quicksand_bold", Sans-serif;
  font-size: 17px;
  font-weight: 600;
}
.bg {
  background-color: #ffffff;
}
.bg:hover {
  background-color: #ffffff;
}
.bg_green {
  background-color: var(--green-color-dark);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}
.above_z {
  position: relative;
  z-index: 2;
}
</style>
